<script setup>
import { useUiStore } from "@/stores/ui";
import { useMerchantStore } from "@/stores/merchant";
import { useOnBoardingStore } from "@/stores/onboarding";
import { useAuthStore } from "@/stores/auth";

const uiStore = useUiStore();
const { logout } = useAuthStore(); // use auth store

const sidebarHidden = computed(() => useUiStore().sidebarHidden);
const profile = computed(() => useMerchantStore().profile);

function toggleSidebar() {
  uiStore.set({ sidebarHidden: !sidebarHidden.value });
}
const signOut = async () => {
  await logout();
  await useMerchantStore().$reset();
  await useOnBoardingStore().$reset();
  await useUiStore().$reset();
  const router = useRouter();
  router.push("/login/");
};
</script>

<template>
  <header
    class="h-14 w-full px-5 py-2.5 flex items-center justify-between border-b border-[#E5E7EB] sticky top-0 z-40 flex-none bg-white"
    :class="profile.kyc_status === 'verified' ? 'z-[9999]' : 'z-40'"
  >
    <div class="flex">
      <button class="mr-6" @click="toggleSidebar">
        <img src="/icons/bars.svg" alt="navigation bars" />
      </button>
      <NuxtLink to="/">
        <img
          src="/images/flik-logo.png"
          alt="Flik Logo"
          class="object-contain h-7"
          height="30"
        />
      </NuxtLink>
    </div>

    <div class="flex items-center">
      <!-- <button>
        <img src="/icons/bell.svg" alt="notification bell" />
      </button> -->
      <!-- <div class="w-px h-6 bg-[#E5E7EB] ml-5 mr-7"></div> -->
      <button
        id="menuHeaderButton"
        class="flex items-center"
        data-dropdown-toggle="menuHeader"
      >
        <div
          class="bg-blue-600 rounded-full w-6 h-6 flex items-center justify-center"
          :class="isMobile() ? 'mr-2' : ''"
        >
          <img src="/icons/default-store.svg" alt="default store" />
        </div>
        <span
          v-if="!isMobile()"
          class="block mx-2 text-sm font-medium uppercase"
        >
          {{ profile.brand_name || "Merchant Name" }}
        </span>
        <img src="/icons/chevron-down.svg" alt="store navigation chevron" />
      </button>

      <div
        id="menuHeader"
        class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600"
      >
        <div
          v-if="isMobile()"
          class="px-4 py-3 text-sm text-gray-900 dark:text-white"
        >
          <div class="text-xs text-gray-500 truncate">
            <!-- {{ profile.email }} -->
            {{ profile.brand_name || "Merchant Name" }}
          </div>
          <!-- <span class="font-medium">Manage Account</span> -->
        </div>
        <!-- <a
          href="#"
          class="font-medium block px-4 py-3 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
        >
          Manage Store
        </a>
        <a
          href="#"
          class="font-medium block px-4 py-3 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
        >
          Help Center
        </a> -->
        <button
          class="w-full text-left block px-4 py-3 text-sm text-red-500 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
          @click="signOut()"
        >
          Sign out
        </button>
      </div>
    </div>
  </header>
</template>
