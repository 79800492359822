<script setup>
import { onMounted } from "vue";
import { initFlowbite } from "flowbite";
import { useRoute } from "vue-router";

const route = useRoute();

// initialize components based on data attribute selectors
onMounted(() => {
  initFlowbite();
});
</script>
<template>
  <div>
    <Notif />
    <Topbar />
    <div class="lg:flex">
      <Sidebar />
      <main
        :class="route.path === '/wallet' ? 'bg-[#F9FAFB]' : 'bg-white'"
        class="flex-auto w-full min-w-0 lg:static lg:max-h-full lg:overflow-visible p-6"
      >
        <slot />
      </main>
    </div>
  </div>
</template>
