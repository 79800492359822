<script setup lang="ts">
import menus from "../../constants/menus";
import { isMobile } from "../../composables/helper.ts";
import { useUiStore } from "@/stores/ui";

const innerMenuHeight = ref(0);
const route = useRoute();
const selectedMenuId = ref(-1);
const sidebarHidden = computed(() => useUiStore().sidebarHidden);
if (isMobile()) {
  useUiStore().set({ sidebarHidden: true });
} else {
  useUiStore().set({ sidebarHidden: false });
}

const pathPage = route.path.split("/");
const slugPage = pathPage[1];
const HEIGHT_INNER_MENU_ITEM = 52;

// set parent menu open or not for submenu
menus.forEach((menu) => {
  const menuSplit = menu.link.split("/");
  if (menuSplit[1] === slugPage) {
    if (menu.subMenus) {
      innerMenuHeight.value =
        menu.subMenus.length * HEIGHT_INNER_MENU_ITEM - 16;
    }
    selectedMenuId.value = menu.id;
  }
});

const onToggleInnerMenu = (menu: Record<string, any>) => {
  if (innerMenuHeight.value > 0 && selectedMenuId.value === menu.id) {
    innerMenuHeight.value = 0;
  } else {
    innerMenuHeight.value = menu.subMenus.length * HEIGHT_INNER_MENU_ITEM - 16;
    selectedMenuId.value = menu.id;
  }
};
const setActiveMenu = (link) => {
  if (route.path === "/") {
    return link === route.path;
  }
  return link.includes(route.path);
};

onBeforeMount(() => {
  const router = useRouter();
  router.beforeEach((to, from, next) => {
    if (isMobile()) {
      useUiStore().set({ sidebarHidden: true });
    }
    next();
  });
});

const handleBeforeRedirect = (link) => {
  const router = useRouter();
  if (isMobile()) {
    useUiStore().set({ sidebarHidden: true });
  }
  router.push(link);
};
</script>

<template>
  <aside
    class="fixed inset-0 z-20 flex-none h-full w-0 lg:static lg:h-auto lg:overflow-y-visible lg:pt-0 lg:block top-[56px] lg:top-0"
    :class="
      sidebarHidden ? 'md:w-[68px] overflow-hidden' : 'w-full md:w-[250px]'
    "
  >
    <nav
      class="transition-left duration-300 ease-in-out py-4 px-3 border-r border-[#E5E7EB] fixed h-full md:h-[calc(100%-56px)] text-[#374151] flex flex-col justify-between bg-white"
      :class="
        sidebarHidden
          ? '-left-[68px] md:left-0 w-[68px] overflow-hidden'
          : 'left-0 w-full md:w-[250px]'
      "
    >
      <ul>
        <li
          v-for="menu in menus"
          :key="menu.id"
          class="text-sm font-medium cursor-pointer"
        >
          <NuxtLink
            class="flex items-center p-2 rounded hover:bg-[#F3F4F6] mb-4 cursor-pointer"
            :class="setActiveMenu(menu.link) ? 'bg-gray-100 rounded' : ''"
            :to="menu.subMenus?.length > 0 ? '' : menu.link"
            @click="menu.subMenus?.length > 0 ? onToggleInnerMenu(menu) : {}"
            @click.native="
              menu.subMenus?.length > 0 ? {} : handleBeforeRedirect(menu.link)
            "
          >
            <div class="w-9 shrink-0">
              <img :src="menu.icon" :alt="menu.label" />
            </div>
            <span
              class="flex-1 transition-opacity delay-100 duration-300 ease-in-out whitespace-nowrap"
              :class="sidebarHidden ? 'opacity-0' : 'opacity-100'"
              >{{ menu.label }}</span
            >
            <img
              v-if="menu.subMenus"
              src="/icons/chevron-down.svg"
              alt="chevron down"
              class="transition-all ease-in-out duration-300 delay-100"
              :class="sidebarHidden ? 'opacity-0' : 'opacity-100'"
              :style="{
                transform: `rotate(${
                  menu.id === selectedMenuId && innerMenuHeight > 0 ? -180 : 0
                }deg)`,
              }"
            />
          </NuxtLink>

          <ul
            v-if="menu.subMenus"
            class="overflow-hidden transition-all ease-in-out duration-200"
            :style="{
              height: `${menu.id === selectedMenuId ? innerMenuHeight : 0}px`,
            }"
          >
            <li
              v-for="(subMenu, index) in menu.subMenus"
              :key="subMenu.id"
              class="cursor-pointer hover:text-[#111827]"
              :class="menu.subMenus.length === index + 1 ? '' : 'mb-2'"
            >
              <NuxtLink
                :to="subMenu.link"
                class="block p-2"
                :class="[
                  setActiveMenu(subMenu.link) ? 'bg-gray-100 rounded' : '',
                  sidebarHidden ? 'text-center' : 'p-2 pl-9',
                ]"
                @click.native="handleBeforeRedirect(subMenu.link)"
              >
                {{ sidebarHidden ? subMenu.short : subMenu.label }}
              </NuxtLink>
            </li>
          </ul>
        </li>
      </ul>

      <!-- <NuxtLink class="text-sm font-medium cursor-pointer p-2 flex">
        <div class="w-9 shrink-0">
          <img src="/icons/question-circle.svg" alt="FLIK Help Center" />
        </div>
        <span
          class="transition-opacity delay-100 duration-300 ease-in-out whitespace-nowrap"
          :class="sidebarHidden ? 'opacity-0' : 'opacity-100'"
          >FLIK Help Center</span
        >
      </NuxtLink> -->
    </nav>
  </aside>
</template>
