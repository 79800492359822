interface IMenu {
  id: number;
  label: string;
  link: string;
  icon: string;
  subMenus?: {
    id: number;
    label: string;
    link: string;
    short: string;
  }[];
}

function getReportSubmenus(): {
  id: number;
  label: string;
  link: string;
  short: string;
}[] {
  let reportSubmenus = [
    {
      id: 201,
      label: "Transaction",
      short: "TR",
      link: "/report/transaction/",
    },
  ];

  const config = useRuntimeConfig();
  if (config.public.SHOW_SETTLEMENT_REPORT == "true") {
    reportSubmenus.push({
      id: 202,
      label: "Settlement",
      short: "ST",
      link: "/report/settlement/",
    });
  }

  reportSubmenus.push({
    id: 203,
    label: "Customer",
    short: "CR",
    link: "/report/customer/",
  });

  return reportSubmenus;
}

const menus: IMenu[] = [
  {
    id: 1,
    label: "Dashboard",
    link: "/",
    icon: "/icons/document-report.svg",
  },
  {
    id: 6,
    label: "Manual Order",
    link: "/manual-order",
    icon: "/icons/cart-add.svg",
  },
  {
    id: 2,
    label: "Report",
    link: "/report",
    icon: "/icons/chart-pie.svg",
    subMenus: getReportSubmenus(),
  },
  {
    id: 3,
    label: "Promotions",
    link: "/promotions",
    icon: "/icons/sale-percent.svg",
  },
  {
    id: 4,
    label: "Wallet & Payment",
    link: "/wallet",
    icon: "/icons/wallet.svg",
  },
  {
    id: 5,
    label: "Post Purchase Offer",
    link: "/post-purchase-offer",
    icon: "/icons/basket.svg",
    subMenus: [
      {
        id: 301,
        label: "List of PPO",
        short: "LP",
        link: "/post-purchase-offer/list",
      },
      {
        id: 302,
        label: "Analytics",
        short: "AN",
        link: "/post-purchase-offer/analytics",
      },
    ],
  },
  {
    id: 6,
    label: "Configurations",
    link: "/configurations",
    icon: "/icons/configuration.svg",
    subMenus: [
      {
        id: 401,
        label: "Payment Method",
        short: "PM",
        link: "/configurations/payment-method",
      },
      {
        id: 402,
        label: "Shipping Method",
        short: "SM",
        link: "/configurations/shipping-method",
      },
      {
        id: 403,
        label: "Warehouse",
        short: "WH",
        link: "/configurations/warehouse",
      },
      {
        id: 404,
        label: "External Ads",
        short: "EA",
        link: "/configurations/external-ads",
      },
    ],
  },
  // {
  //   id: 5,
  //   label: "Exported Files",
  //   link: "/export",
  //   icon: "/icons/export-files.svg",
  // },
];

export default menus;
